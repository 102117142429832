"use client";

import { useState, useEffect, Fragment } from "react";
import type { JSX } from "react";
import { useSearchParams, usePathname } from "next/navigation";
import Link from "next/link";
import Image from "next/image";
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon, ChevronDownIcon } from "@heroicons/react/16/solid";
import ButtonSignin from "./ButtonSignin";
import logo from "@/public/icon.png";
import config from "@/config";
import { useTranslations } from 'next-intl';
import { languages } from '../navigation'


const removeLanguagePrefix = (path: string) => {
  const segments = path.split('/').filter(segment => segment !== '');
  if (languages.some(lang => lang.locale === segments[0])) {
    return '/' + segments.slice(1).join('/');
  }
  return path;
};

const Header = (params: any) => {
  const t = useTranslations('Header');
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const locale: string = 'default'; // or fetch from context/state
  console.log('params ======', params);
  const { locale } = params;


  const cta: JSX.Element = <ButtonSignin text={t('login')} extraStyle="btn-primary" />;

// Home
// Features
// How It Works
// Pricing
// FAQ
  const links: { href: string; label: string; }[] = [
    { href: "/", label: t('Home') },
    { href: "/#features", label: t('Feartures') },
    { href: "/#how-it-works", label: t('How It Works') },
    { href: "/#pricing", label: t('Pricing') },
    { href: "/#faq", label: t('FAQ') },
  ];

  useEffect(() => {
    setIsOpen(false);
  }, [searchParams]);

  return (
    <header className="bg-base-200">
      <nav className="container flex items-center justify-between px-8 py-4 mx-auto" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link className="flex items-center gap-2 shrink-0 " href="/" title={`${config.appName} homepage`}>
            <Image
              src={logo}
              alt={`${config.appName} logo`}
              className="w-8"
              placeholder="blur"
              priority={true}
              width={32}
              height={32}
            />
            <span className="font-extrabold text-lg">{config.appName}</span>
          </Link>
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setIsOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-base-content"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>

        <div className="hidden lg:flex lg:justify-center lg:gap-12 lg:items-center">
          {links.map((link) => (
            <Link href={link.href} key={link.href} className="link link-hover" title={link.label}>
              {link.label}
            </Link>
          ))}
        </div>

        <div className="hidden lg:flex lg:justify-end lg:flex-1 items-center gap-4">

          <a href="https://twitter.com/intent/user?&region=follow&screen_name=replyrealm" className="ml-6 text-gray-400 hover:text-gray-500 flex items-center">
            <svg className="h-6 w-6 " viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
            <span>@ReplyRealm</span>
          </a>

          {cta}
          <Menu as="div" className="relative inline-block text-left z-30">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 border border-[rgba(255,255,255,0.5)] rounded-md px-3 py-2 text-sm font-semibold hover:border-[rgba(255,255,255,0.9)]">
                <GlobeAltIcon className="w-5 h-5" />
                {locale == 'default' ? 'EN' : locale.toUpperCase()}
                <ChevronDownIcon className="ltr:-mr-1 rtl:-ml-1 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-30 mt-2 w-26 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1 z-30">
                  {languages.map((item) => {

                    let hrefValue = `/${item.locale}${removeLanguagePrefix(pathname)}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
                    // console.log('hrefValue======', hrefValue, pathname, searchParams);
                    return (
                      <Menu.Item key={item.locale}>
                        <Link href={hrefValue} className={"z-30"}>
                          <span className={'text-gray-700 block px-4 py-2 text-sm hover:text-[#2d6ae0] z-30'}>
                            {item.language}
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </nav>

      <div className={`relative z-50 ${isOpen ? "" : "hidden"}`}>
        <div className={`fixed inset-y-0 right-0 z-10 w-full px-8 py-4 overflow-y-auto bg-base-200 sm:max-w-sm sm:ring-1 sm:ring-neutral/10 transform origin-right transition ease-in-out duration-300`}>
          <div className="flex items-center justify-between">
            <Link className="flex items-center gap-2 shrink-0 " title={`${config.appName} homepage`} href="/">
              <Image
                src={logo}
                alt={`${config.appName} logo`}
                className="w-8"
                placeholder="blur"
                priority={true}
                width={32}
                height={32}
              />
              <span className="font-extrabold text-lg">{config.appName}</span>
            </Link>
            <button type="button" className="-m-2.5 rounded-md p-2.5" onClick={() => setIsOpen(false)}>
              <span className="sr-only">Close menu</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="flow-root mt-6">
            <div className="py-4">
              <div className="flex flex-col gap-y-4 items-start">
                {links.map((link) => (
                  <Link href={link.href} key={link.href} className="link link-hover" title={link.label}>
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
            <div className="divider"></div>
            <div className="flex flex-col">{cta}</div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
